<template>
    <uw-content title="产品报废">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.invalidCreate.Open()" >新建报废单</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>
            
            <!-- 检索 -->
            <template slot="search">
                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.number" clearable  size="mini" placeholder="报废单号"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <!-- 状态 -->
                    <vxe-column width="80" title="状态">
                        <template #default="{ row }">
                            <el-tag effect="dark" size="mini" v-if="row.state">已报废</el-tag>
                            <el-tag effect="dark" size="mini" v-if="!row.state" type="danger">已取消</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 报废单号 -->
                    <vxe-column width="140" title="报废单号">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.invalidDrawer.Open(row.id)">{{ row.number }}</span>
                        </template>
                    </vxe-column>

                    <!-- 出库信息 -->
                    <vxe-column min-width="140" field="date" title="报废时间"></vxe-column>
                    <vxe-column min-width="140" field="describe" title="报废备注"></vxe-column>
                    <vxe-column min-width="80" field="created_user.name" title="负责人"></vxe-column>
                    <vxe-column min-width="150" field="created_at" title="创建时间"></vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <ims-product-invalid-create ref="invalidCreate" @onChange="TableSearch" />
        <ims-product-invalid-drawer ref="invalidDrawer" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                warehoser_id: null,
                number: null,
                action: 0
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    // 计算属性
    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 数据查询
        TableSearch() {
            this.$http.post(
                '/9api/ims/product-invalid/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_user']
                }
            ).then(rps => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body
                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        }

    }

}
</script>